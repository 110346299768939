<template>
	<!-- 전체 -->
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<!-- (구)AI-스피커설정 -->
			<b>스피커대상물 설정</b>
		</div>
		<!-- 현장 조회 -->
		<div class="panel panel-flat">
			<div class="panel-body" style="width: 870px; margin: 20px 0px 0px auto; padding-left: 0px; padding-bottom: 0px">
				<div class="row">
					<!-- 조회할 현장 -->
					<div class="col-md-3" style="width: 400px">
						<div class="form-group">
							<label style="width: 80px">{{ detailsFieldMap.searchSite }}</label>
							<div class="select-wrapper">
								<select2 v-model="searchSite" :options="options.siteOptions" :disabled="isSiteDisabled">
									<option value="">선택</option>
								</select2>
							</div>
						</div>
					</div>
					<!-- 검색 버튼 -->
					<div class="mb-10 text-right" style="float: right">
						<button @click="searchSiteInfo" class="btn btn-labeled bg-primary">
							<b><i class="icon-search4"></i></b>
							조회
						</button>
					</div>
				</div>
			</div>
		</div>
		<!-- // 현장 조회 -->

		<!-- 대상물별 스피커 설정 정보 -->
		<div class="wrapper">
			<!-- <h5 class="panel-title" style="padding-bottom: 10px"><b>대상물별 스피커 설정</b></h5> -->
			<div class="table-wrapper" speaker-object>
				<table class="table table-hover">
					<thead>
						<tr>
							<th width="50px">대상물</th>
							<th width="120px" v-for="(row, index) in speakerInfo" :key="index">
								{{ row.speakerNm }}&nbsp;
								<input type="checkbox" :id="'checked_box' + row.speakerId" @input="checkValueSpeakerAllCheckBox(row.speakerId, $event)" />
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row, index) in speakerAiConfig" :key="index">
							<td style="text-align: left">{{ row.objectNm }}</td>
							<td v-for="(r, i) in row.aiConfigSpeakerCheckVOs" :key="i">
								<input type="checkbox" v-model="r.checked" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="mb-10 mt-10 text-right">
				<button @click="insSpeakerAiConfig" class="btn btn-labeled bg-primary">
					<b><i class="icon-checkmark3"></i></b>
					적용
				</button>
			</div>
		</div>
		<!-- // 위험 작업 -->
	</div>
	<!-- // 전체 -->
</template>

<script>
import Select2 from '@/components/Select2';
import apiIndex from '../../api/index';

let axiosExtention;

const speakerAiConfigApi = apiIndex.speakerAiConfig;

export default {
	components: {
		Select2: Select2,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('speakerAiConfigPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		if (this.pageParam.loginUserRolecd !== '00') {
			this.isSiteDisabled = true;
		}
		this.pageParam.siteOptions.forEach(e => {
			e.text = e.siteNm;
			e.value = e.siteId;
		});
		this.options.siteOptions = this.pageParam.siteOptions;
		this.searchSite = this.pageParam.loginUserSiteId;

		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.searchSiteInfo();
	},
	mounted() {
		this.aiConfigSpeaker = document.getElementsByClassName('InqSpeakerAiConfigOutVO');
	},
	data: () => ({
		apiUrl: {
			inqSpeakerInfo: speakerAiConfigApi.inqSpeakerInfo,
			inqSpeakerAiConfig: speakerAiConfigApi.inqSpeakerAiConfig,
			insSpeakerAiConfig: speakerAiConfigApi.insSpeakerAiConfig,
		},
		pageParam: null,
		//상세필드명맵
		detailsFieldMap: {
			searchSite: '현장 조회',
		},
		options: {
			siteOptions: '',
		},
		debug: false,
		// tempSearchSite: null,
		searchSite: null,
		speakerInfo: null,
		speakerInfoCount: 0,
		speakerAiConfig: [],
		isSiteDisabled: false,
		speakerObjectCount: 0,
	}),
	computed: {
		speakerCheckStatus() {
			var speakerCheckBoxValues = new Map();
			this.speakerAiConfig.forEach(speakerObjects => {
				speakerObjects.aiConfigSpeakerCheckVOs.forEach(checkVO => {
					if (speakerCheckBoxValues[checkVO.speakerId] == undefined) {
						this.$set(speakerCheckBoxValues, checkVO.speakerId, new Array(checkVO.checked));
					} else {
						speakerCheckBoxValues[checkVO.speakerId].push(checkVO.checked);
					}
				});
			});
			return speakerCheckBoxValues;
		},
	},
	watch: {
		speakerCheckStatus() {
			var speakerCheckBoxValues = this.speakerCheckStatus;
			if (Object.keys(speakerCheckBoxValues).length != 0) {
				this.speakerInfo.forEach(speaker => {
					let allCheckBox = document.getElementById('checked_box' + speaker.speakerId);
					if (speakerCheckBoxValues[speaker.speakerId].filter(booleanValues => booleanValues == true).length == this.speakerObjectCount) {
						allCheckBox.checked = true;
					} else {
						allCheckBox.checked = false;
					}
				});
			}
		},
	},
	methods: {
		checkValueSpeakerAllCheckBox(speakerId, event) {
			let allCheckBoxValue = event.target.checked;
			this.speakerAiConfig.forEach(speakerObjects => {
				speakerObjects.aiConfigSpeakerCheckVOs.forEach(checkVO => {
					if (checkVO.speakerId == speakerId) {
						checkVO.checked = allCheckBoxValue;
					}
				});
			});
		},
		checkItHaveSpeakers() {
			if (this.speakerInfo == undefined || this.speakerInfo.length == 0) {
				setTimeout(() => window.alert('해당 현장에 스피커가 없습니다.\n스피커를 먼저 등록해주세요'), 500);
			}
		},
		async getSpeakerInfo() {
			await this.$axios
				.post(this.apiUrl.inqSpeakerInfo, { siteId: this.searchSite })
				.then(
					function (r) {
						this.speakerInfo = r.data;
						if (this.speakerInfo !== 'null') {
							this.speakerInfoCount = this.speakerInfo.length;
						} else {
							this.speakerInfo = null;
						}
						this.checkItHaveSpeakers();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		getSpeakerAiconfig() {
			this.$axios
				.post(this.apiUrl.inqSpeakerAiConfig, { siteId: this.searchSite })
				.then(
					function (r) {
						this.speakerAiConfig = r.data;
						this.speakerObjectCount = this.speakerAiConfig.length;
						this.changeSpeakerAiConfig();
					}.bind(this),
				)
				.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
		},
		// 현장의 설정 정보들 조회
		async searchSiteInfo() {
			if (!this.searchSite) {
				alert('현장을 선택해주세요.');
				return;
			}

			await this.getSpeakerInfo();
			this.getSpeakerAiconfig();
		},
		// 값이 있으면 checked 변경
		changeSpeakerAiConfig() {
			if (!this.searchSite) {
				alert('현장을 선택해주세요.');
				return;
			}
			this.speakerAiConfig.forEach(e => {
				e.siteId = this.searchSite;

				e.aiConfigSpeakerCheckVOs = [];

				// 최초 모든 checked 값 스피커 개수에 맞춰 false로 등록
				for (let i = 0; i < this.speakerInfoCount; i++) {
					let checked = { speakerId: this.speakerInfo[i].speakerId, checked: false }; // 배열로 했더니 바인딩이 안되서 객체로 변경
					e.aiConfigSpeakerCheckVOs.push(checked);
				}

				// DB에 저장된 speakerId 가 존재하면
				if (e.speakerIds) {
					let speakerIds = e.speakerIds.split(',');
					speakerIds.forEach(speakerId => {
						for (let i = 0; i < this.speakerInfoCount; i++) {
							// speakerId speaker index 정보의 id가 같으면
							if (speakerId === this.speakerInfo[i].speakerId) {
								// 해당 자리 값 true로 변경
								e.aiConfigSpeakerCheckVOs[i].checked = true;
								break;
							}
						}
					});
				}
			});
		},
		insSpeakerAiConfig() {
			if (confirm('적용하시겠습니까?')) {
				const insSpeakerAiConfigInVOs = this.speakerAiConfig;
				this.$axios
					.post(this.apiUrl.insSpeakerAiConfig, insSpeakerAiConfigInVOs)
					.then(
						function () {
							// 성공하면 다시 조회
							this.getSpeakerAiconfig();
							alert('적용되었습니다.');
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler(this.detailsFieldMap));
			}
		},
	},
};
</script>

<style scoped>
.content {
	padding-bottom: 20px;
}

[v-cloak] > * {
	display: none !important;
}

.select-wrapper {
	min-width: 250px !important;
	width: 250px !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 60%;
	margin-top: 5px;
	max-width: 130px;
}

.row .ai-config-all {
	display: flex;
	float: left;
	flex: 1;
	justify-content: center;
	align-items: center;
}
.ai-config-all > label {
	margin-top: 8px;
}
.ai-config-all > input {
	margin-left: 20px;
	margin-right: 10px;
	width: 48px;
	text-align: center;
}

/* table */
.table-wrapper {
	border-collapse: collapse;
	overflow-x: auto;
	overflow-y: auto;
	border-color: #dedee0;
	border-style: solid;
	border-width: 1px 1px 1px 1px;
	position: relative;
	color: #515967;

	background-color: #fff;
}

[speaker-object] {
	height: 470px;
}

.table {
	table-layout: fixed;
}
/* table head */
.table-wrapper .table > thead > tr > th {
	background-color: #f5f5f5;

	text-align: center;

	padding: 0.5em 0.6em 0.4em 0.6em;
	font-weight: 700;
	white-space: nowrap;
	text-overflow: ellipsis;

	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;

	overflow: auto;
	position: sticky;
	top: 0;
	box-shadow: inset 0 -1px 0 #dedee0;
}

.table-wrapper .table > thead > tr > th:first-child {
	border-width: 0 0 0 0;
}

/* table body */
/* 홀수 */
.table-wrapper .table > tbody > tr:nth-child(2n + 1) {
	background-color: #fff;
}
/* 짝수 */
.table-wrapper .table > tbody > tr:nth-child(2n) {
	background-color: #fbfbfb;
}

.table-wrapper .table > tbody > tr:hover {
	background-color: #b5bdcb;
	background-image: url(/lib/kendo-r2-2019/styles/textures/highlight.png);
	background-position: 50% 50%;
}

.table-wrapper .table > tbody > tr > td:first-child {
	border-width: 0 0 0 0;
}

.table-wrapper .table > tbody > tr > td {
	border-color: #dedee0;
	border-style: solid;
	border-width: 0 0 0 1px;
	padding: 0.4em 0.6em;
	overflow: hidden;
	line-height: 1.6em;
	vertical-align: middle;
	text-overflow: ellipsis;
}

.table-wrapper .table > tbody > tr > td {
	text-align: center;
}

/* table empty */
.table [empty] {
	display: none;
	height: 166px;
}
.table [empty] td {
	padding: 30px;
	font-size: 1.2em;
	text-align: center;
}
.table [empty]:first-child:last-child {
	display: table-row;
}
</style>
